import { redirect } from "react-router-dom";
import { useEffect } from "react";

export default function PsychVideo() {
    useEffect(() => {
          window.location.replace('https://youtu.be/G_21DftaHq0');
      }, []);
    
      return <></>;
}
